export const darkTheme = {
  primary: "#282a36",
  secondary: "#3e2326",
  accent: "#50fa7b",
  textPrimary: "#747687",
  textSecondary: "#a3a9ce",

  // primary: "#2E3440",
  // secondary: "#3B4252",
  // textPrimary: "#D8DEE9",
  // textSecondary: "#8FBCBB",
  // textAccent: "#88C0D0",
};

export const lightTheme = {
  // primary: "#b6a999",
  // secondary: "#796d5d",
  // textPrimary: "#99b0a8",
  // textSecondary: "#58756a",
  // textAccent: "#a6a7a5",

  // primary: "#796d5d",
  // secondary: "#615e59",
  // accent: "#baae97",
  // textPrimary: "#a6a7a5",
  // textSecondary: "#1d0f0b",

  primary: "#fffcf2",
  secondary: "#ccc5b9",
  accent: "#eb5e28",
  textPrimary: "#403d39",
  textSecondary: "#252422",
};
